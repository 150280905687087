import { Cookie } from '@frontend-components/utils/cookie'
import { authCookieName } from '@frontend-components/constants'

export function getAuthToken(): string {
  let token = ''
  const cookie = Cookie.get(authCookieName)

  if (cookie != null) {
    token = JSON.parse(cookie).token
  }

  return token
}

export * from './AcceptedFileExtensions'
export * from './lawdocs'

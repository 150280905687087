import {
  FC, useState, useRef
} from 'react'

import { useTranslation } from 'react-i18next'

import { menuLinks, cartFlaggedMenuLinks } from './Menu.constants'
import {
  Button, Link as MuiLink, MenuItem, Menu as MuiMenu
} from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import { Link } from 'react-router-dom'
import { useFlag } from '@unleash/proxy-client-react'

const Menu: FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isMouseOver, setIsMouseOver] = useState(false)
  const menuButtonRef = useRef<HTMLButtonElement>(null)
  const { t } = useTranslation()

  const isEcommerceUserCartEnabled = useFlag('EcommerceUserCart')

  const menuLinksToUse = isEcommerceUserCartEnabled ? cartFlaggedMenuLinks : menuLinks

  const handleMouseOver = () => {
    if (!isMouseOver) {
      setIsMenuOpen(true)
      setIsMouseOver(true)
    } else {
      setIsMouseOver(false)
    }
  }

  const handleCloseMenu = () => {
    setIsMenuOpen(false)
    setIsMouseOver(true)
  }

  return (
    <>
      <Button
        sx={{
          display: 'flex', gap: 1, alignItems: 'center', textTransform: 'none', height: '100%'
        }}
        data-cy="authMenu-title"
        ref={menuButtonRef}
        onClick={() => setIsMenuOpen((prevOpen) => !prevOpen)}
        onMouseOver={handleMouseOver}
        onMouseLeave={() => setIsMouseOver(false)}
      >
        {t('appHeader.menu.title')}
        <ExpandMore
          sx={[
            isMenuOpen && {
              transform: 'rotate(180deg)'
            }
          ]}
        />
      </Button>
      <MuiMenu
        anchorEl={menuButtonRef.current}
        open={isMenuOpen}
        onClose={handleCloseMenu}
        disableScrollLock
      >
        <div onMouseLeave={() => setIsMenuOpen(false)}>
          {menuLinksToUse.map((link) => {
            if (link.name !== 'support') {
              return (
                <MenuItem key={link.name}>
                  <MuiLink component={Link} to={link.url} underline="none">
                    {t(`appHeader.menu.${link.name}`)}
                  </MuiLink>
                </MenuItem>
              )
            }
            return (
              <MenuItem key={link.name}>
                <MuiLink href={link.url} target="_blank" underline="none">
                  {t(`appHeader.menu.${link.name}`)}
                </MuiLink>
              </MenuItem>
            )
          })}
        </div>
      </MuiMenu>
    </>
  )
}

export default Menu

import React, {
  FC,
  useState,
  useEffect,
  useRef
} from 'react'
import cx from 'classnames'

import { useContext } from '../context'
import { extractTextFromReact } from '../../../utils/extractTextFromReact'
import { DeviceDetection } from '../../../utils/deviceDetection'
import { useThrottle } from '../../../hooks/useThrottle'

import InputWrapper from '../../../helperComponents/InputWrapper'
import Dropdown from './Dropdown'
import IconButton from '../../IconButton'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faChevronDown, faXmark } from '@fortawesome/pro-regular-svg-icons'
import { SelectProps } from '../Select.types'
import { SelectConstants } from '../Select.constants'
import styles from '../Select.module.scss'

const isMacintosh = DeviceDetection.isMacintosh()

const StyledSelect: FC<SelectProps> = ({
  label,
  name,
  disabled,
  placeholder,
  selectExtraProps,
  isSearchable = false,
  emptySearchMessage,
  maxHeight,
  dropdownWidth,
  children,
  classNames,
  selectedText,
  isMultiSelect = false,
  kind,
  inputSize,
  onOpen,
  onClose,
  isLoading = false,
  ...restProps
}) => {
  const [isFocused, setFocused] = useState(false)
  const [isOpen, setOpen] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [isValueSelectedWithEnterKey, setValueSelectedWithEnterKey] = useState(false)
  const [optionsToRender, setOptionsToRender] = useState(React.Children.toArray(children))
  const {
    selectedValue,
    setSelectedValue,
    highlightedItemIndex,
    setHighlightedItemIndex,
    isMouseHighlightDisabled,
    setMouseHighlightDisabled
  } = useContext()

  const inputWrapperRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  const areaLabelText = restProps['aria-label'] ?? extractTextFromReact(label ?? placeholder)
  const dropdownIcon = isOpen ? faChevronUp : faChevronDown
  const selectedOptionNode = getSelectedOptionNode(optionsToRender)
  const inputWrapperValue = selectedValue !== placeholder && searchValue.length === 0 ? selectedValue : ''
  const selectedOptionText = selectedText ?? extractTextFromReact(selectedOptionNode?.props?.children)
  const throttledSearchValue = isSearchable ? useThrottle(searchValue, SelectConstants.searchKeydownTimeoutMs) : ''
  const isPlaceholderVisible = !(isMultiSelect && selectedOptionNode.length > 0)

  const handleClickDeleteMultiOption = (event: any, value: string) => {
    event.stopPropagation()

    if (Array.isArray(selectedValue)) {
      setSelectedValue(selectedValue.filter((item) => item !== value))
      setOpen(false)
    }
  }

  useEffect(() => {
    setOptionsToRender(getOptionNodesToRender())
  }, [isLoading, throttledSearchValue])

  const dropdownElement = isOpen ? (
    <Dropdown
      inputWrapperRef={inputWrapperRef}
      emptySearchMessage={emptySearchMessage}
      maxHeight={maxHeight}
      dropdownWidth={dropdownWidth}
      name={name}
      isLoading={isLoading}
    >
      {optionsToRender}
    </Dropdown>
  ) : null

  return (
    <div
      role="presentation"
      ref={inputWrapperRef}
      onClick={handleClickInputWrapper}
      className={cx(classNames?.wrapper)}
    >
      <InputWrapper
        {...restProps}
        name={name}
        value={inputWrapperValue}
        isFocused={isFocused}
        label={label}
        rightIcon={<FontAwesomeIcon icon={dropdownIcon} />}
        disabled={disabled}
        kind={kind}
        inputSize={inputSize}
        {...(isPlaceholderVisible ? { placeholder } : {})}
      >
        <input
          {...selectExtraProps}
          aria-label={areaLabelText}
          name={name}
          disabled={disabled}
          ref={inputRef}
          value={isFocused && isSearchable && !isValueSelectedWithEnterKey ? searchValue : selectedOptionText}
          onChange={handleChangeSearchValue}
          onFocus={() => setFocused(true)}
          onBlur={handleBlurInput}
          onKeyDown={handleKeyDownDropdown}
          className={cx(classNames?.input, styles.styledSelect, {
            [styles.notTypeable]: !isSearchable,
            [styles.disabled]: disabled,
            [styles.isMultiSelect]: isMultiSelect
          })}
          autoComplete="off"
          {...(isPlaceholderVisible ? { placeholder } : {})}
        />
        {isMultiSelect && selectedOptionNode.length > 0 ? (
          <div
            className={cx(styles.multiOptionsWrapper, styles[kind as string], styles[inputSize as string])}
            data-cy="multi-options-list"
          >
            {(selectedOptionNode as any[]).map(({ props }) => (
              <p
                key={props.value}
                className={styles.multiOption}
                data-cy="multi-options-item"
              >
                <span>{extractTextFromReact(props.children)}</span>
                <IconButton
                  title="Remove"
                  onClick={(event) => handleClickDeleteMultiOption(event, props.value)}
                  icon={faXmark}
                  className={styles.deleteButton}
                  data-cy="remove-multi-options-item"
                />
              </p>
            ))}
          </div>
        ) : null}
      </InputWrapper>
      {dropdownElement}
    </div>
  )

  function closeDropdown() {
    closeSelect()
    if (highlightedItemIndex > 0) {
      setHighlightedItemIndex(-1)
    }
    if (isMouseHighlightDisabled) {
      setMouseHighlightDisabled(false)
    }
  }

  function handleChangeSearchValue(event: React.ChangeEvent<HTMLInputElement>) {
    if (!isSearchable || disabled || isMultiSelect) return

    if (isValueSelectedWithEnterKey) {
      setValueSelectedWithEnterKey(false)
    }

    setSearchValue(event.target.value)
    if (!isOpen) openSelect()
  }

  function handleClickInputWrapper() {
    if (disabled) return

    if (!isOpen) {
      openSelect()
    } else {
      closeDropdown()
      if (onClose != null) onClose()
    }
  }

  function handleBlurInput() {
    setFocused(false)
    if (searchValue.length > 0) {
      setSearchValue('')
    }
    closeDropdown()
  }

  function getOptionNodesToRender() {
    const options = React.Children.toArray(children)

    return options.filter((option) => {
      if (!isSearchable) {
        return option
      }

      const optionText = React.isValidElement(option) ? extractTextFromReact(option?.props?.children).toLowerCase() : ''
      const trimmedSearchValue = searchValue.trim().toLowerCase()
      if (optionText.includes(trimmedSearchValue)) {
        return option
      }
      return null
    }).map((option, key) => {
      if (React.isValidElement(option)) {
        return React.cloneElement(option, {
          ...option.props,
          index: key,
          className: `${name}-option-${option.props.value}`,
          extraClassName: option.props.className ?? ''
        }, option.props.children)
      }

      return option
    })
  }

  function handleKeyDownDropdown(event: React.KeyboardEvent) {
    const { key } = event

    if (isOpen && key === 'Escape') {
      closeSelect()
    } else if (isOpen && key === 'Enter') {
      event.preventDefault()
      event.stopPropagation()
      const highlightedOption = optionsToRender[highlightedItemIndex]

      if (React.isValidElement(highlightedOption)) {
        if (!Array.isArray(selectedValue)) {
          setSelectedValue(highlightedOption.props.value)
        } else if (selectedValue.includes(highlightedOption.props.value)) {
          setSelectedValue(selectedValue.filter((value) => value !== highlightedOption.props.value))
        } else {
          setSelectedValue([...selectedValue, highlightedOption.props.value])
        }
        closeSelect()
        setValueSelectedWithEnterKey(true)
      }
    } else if (isOpen && ['ArrowUp', 'ArrowDown'].includes(key)) {
      if (!isMouseHighlightDisabled) {
        setMouseHighlightDisabled(true)
      }
      if (key === 'ArrowUp') {
        setHighlightedItemIndex(highlightedItemIndex > 0 ? highlightedItemIndex - 1 : 0)
      } else {
        setHighlightedItemIndex(highlightedItemIndex < optionsToRender.length - 1 ? highlightedItemIndex + 1 : optionsToRender.length - 1)
      }
    } else if (!isOpen && key === ' ' && !isSearchable) { // Space key
      openSelect()
    }

    if (isMacintosh) {
      if (!isOpen && ['ArrowUp', 'ArrowDown'].includes(key)) {
        openSelect()
      }
    } else if (!isOpen && ['ArrowUp', 'ArrowDown'].includes(key)) {
      let selectedOptionIndex = optionsToRender.findIndex((optionChildren) => {
        if (React.isValidElement(optionChildren)) {
          return optionChildren?.props?.value === selectedValue
        }
        return null
      })

      if (key === 'ArrowDown') {
        if (selectedOptionIndex + 1 !== optionsToRender.length) {
          selectedOptionIndex += 1
        }
      } else if (key === 'ArrowUp') {
        if (selectedOptionIndex - 1 >= 0) {
          selectedOptionIndex -= 1
        }
      }

      const optionToSelect = optionsToRender[selectedOptionIndex]
      if (React.isValidElement(optionToSelect)) {
        setSelectedValue(optionToSelect?.props?.value)
      }
    }
  }

  function getSelectedOptionNode(options: any[]) {
    if (isMultiSelect) {
      return options.filter((option) => selectedValue.includes(option.props.value))
    }
    return options.find((option) => option.props.value === selectedValue)
  }

  function openSelect() {
    setOpen(true)
    if (onOpen != null) onOpen()
  }

  function closeSelect() {
    setOpen(false)
    if (onClose != null) onClose()
  }
}

export default StyledSelect

import { FC, PropsWithChildren } from 'react'
import classnames from 'classnames'

import { MessageProps } from './Message.types'
import styles from './Message.module.scss'

const Message: FC<PropsWithChildren<MessageProps>> = ({
  type = 'message',
  className,
  children,
  ...props
}) => (
  <p {...props} className={classnames(styles.message, className, { [styles.error]: type === 'error' })}>
    {children}
  </p>
)

export default Message

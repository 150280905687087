import { FC, useState, useMemo } from 'react'
import Context from './Context'
import Consumer from './Consumer'

import { initValues } from './initValues'
import * as Types from './types'

const Provider: FC<{ children: any }> = ({ children }) => {
  const [selectedValue, setSelectedValue] = useState(children.props.isMultiSelect ? [] : initValues.selectedValue)
  const [highlightedItemIndex, setHighlightedItemIndex] = useState(initValues.highlightedItemIndex)
  const [isNativeSelect, setNativeSelect] = useState(initValues.isNativeSelect)
  const [isMouseHighlightDisabled, setMouseHighlightDisabled] = useState(initValues.isMouseHighlightDisabled)

  const values = useMemo(() => ({
    selectedValue,
    isNativeSelect,
    highlightedItemIndex,
    isMouseHighlightDisabled,
    setSelectedValue: (value: Types.SelectedValueType) => setSelectedValue(value),
    setNativeSelect: (value: Types.IsNativeSelectType) => setNativeSelect(value),
    setHighlightedItemIndex: (index: Types.HighlightedItemIndexType) => setHighlightedItemIndex(index),
    setMouseHighlightDisabled: (value: Types.IsMouseHighlightDisabledType) => setMouseHighlightDisabled(value)
  }), [
    selectedValue,
    isNativeSelect,
    highlightedItemIndex,
    isMouseHighlightDisabled
  ])

  return (
    <Context.Provider value={values}>
      <Consumer>
        {children}
      </Consumer>
    </Context.Provider>
  )
}

export default Provider

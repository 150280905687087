import {
  useState, useEffect, useRef, useCallback
} from 'react'

export function useThrottle<T>(value: T, timeoutMs: number): T {
  const [state, setState] = useState<T>(value)
  const timeout = useRef<ReturnType<typeof setTimeout>>()

  const clear = useCallback(() => {
    if (timeout.current != null) {
      clearTimeout(timeout.current)
    }
  }, [timeout.current])

  useEffect(() => {
    clear()
    timeout.current = setTimeout(() => setState(value), timeoutMs)

    return () => clear()
  }, [value])

  return state
}

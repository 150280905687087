import { FC } from 'react'
import classnames from 'classnames'

import Spinner from '@frontend-components/components/Spinner'

import { LoadingContainerProps } from './LoadingContainer.types'
import styles from './LoadingContainer.module.scss'

const LoadingContainer: FC<LoadingContainerProps> = ({ className, height }) => (
  <div
    className={classnames(styles.wrapper, className)}
    {...(height != null && height > 0 ? { styles: { height } } : {})}
  >
    <Spinner position="center" />
  </div>
)

export default LoadingContainer

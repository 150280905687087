import { getAuthToken } from '@frontend-components/utils/auth'
import { authHeaderName } from '@frontend-components/constants'
import {
  fetchBaseQuery, FetchArgs, BaseQueryFn, FetchBaseQueryError
} from '@reduxjs/toolkit/query/react'

type FetchBaseQueryArgs = {
  baseUrl?: string
  prepareHeaders?: (headers: Headers, api: {
    getState: () => unknown
  }) => Promise<Headers>
  fetchFn?: (input: RequestInfo, init?: RequestInit | undefined) => Promise<Response>
} & RequestInit

export const baseQueryWithAuth = ({ baseUrl }: FetchBaseQueryArgs): BaseQueryFn<
string | FetchArgs,
unknown,
FetchBaseQueryError
> => async (args, api, extraOptions) => {
  const result = await fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      const authToken = getAuthToken()
      if (authToken != null && authToken && authToken.length > 0) {
        headers.set(authHeaderName, authToken)
      }
      return headers
    }
  })(args, api, extraOptions)

  if (result.meta?.response?.status === 401) {
    console.warn('logout')
  }

  return result
}

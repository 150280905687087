import {
  FC
} from 'react'

import { useGetUserDetailsQuery } from '@store/services/user'

import Logo from './components/Logo'
import { Box } from '@mui/material'
import CartButton from './components/CartButton'
import UserMenu from './components/UserMenu'
import Menu from './components/Menu'
import Notifications from './components/Notifications'

import { themeColors } from '@testavivadk/css-tools/css/themeColors'

const Header: FC = () => {
  const { data: userDetailsData, isLoading: isUserDetailsLoading } = useGetUserDetailsQuery()

  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          height: 48,
          bgcolor: themeColors.hintOfGreen,
          width: '100%',
          zIndex: 29,
          marginBottom: 48
        }}
      >
        <Box
          className="container"
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '100%'
          }}
        >
          <Logo />
          {!isUserDetailsLoading ? (
            <Box
              sx={{
                display: 'flex',
                height: '100%'
              }}
            >
              {userDetailsData?.id != null && (
                <Box display="flex" alignItems="center">
                  <Menu />
                  <CartButton />
                  <Notifications />
                  <UserMenu />
                </Box>
              )}
            </Box>
          ) : null}
        </Box>
      </Box>
      <Box height={48} />
    </>
  )
}

export default Header

import { useEffect, useRef } from 'react'

export function useEventListener(name: string, f: (event: any) => void, target: any = window): void {
  const ready = useRef(false)

  useEffect(() => {
    if (target == null || ready.current === true) {
      return
    }

    ready.current = true
    target.addEventListener(name, f)
    // eslint-disable-next-line consistent-return
    return () => target.removeEventListener(name, f)
  }, [target])
}

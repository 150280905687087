import { Cookie } from '../cookie'
import { authCookieName, authAdminCookieName, authHeaderName } from '../../constants'

export function getAuthToken(): string {
  let token = ''
  const cookie = Cookie.get(authCookieName)

  if (cookie != null) {
    token = JSON.parse(cookie).token
  }

  return token
}

export function getRequestAuthHeader(): { [authHeaderName]: string } {
  return { [authHeaderName]: getAuthToken() }
}

export function getAdminAuthToken(): string {
  let token = ''
  const cookie = Cookie.get(authAdminCookieName)

  if (cookie != null) {
    token = JSON.parse(cookie).token
  }

  return token
}

import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithAuth } from '@store/middleware'

import { applicationUrl } from '@constants'
import { IdentificationFilesTypes } from '@frontend-components/constants'
import { IIdentificationFile } from '@frontend-components/types'

export const IDENTIFICATION_FILES_API_KEY = 'identificationFilesApi'
export const identificationFilesApi = createApi({
  reducerPath: IDENTIFICATION_FILES_API_KEY,
  baseQuery: baseQueryWithAuth({ baseUrl: applicationUrl }),
  tagTypes: ['IDENTIFICATION_FILES'],
  endpoints: (builder) => ({
    getIdentificationFiles: builder.query<IIdentificationFile[], void>({
      query: () => ({
        url: '/api/identificationFile',
        method: 'GET'
      }),
      providesTags: ['IDENTIFICATION_FILES']
    }),
    postIdentificationFile: builder.mutation<void, FormData>({
      query: (formData) => ({
        url: '/api/identificationFile',
        method: 'POST',
        body: formData,
        responseHandler: 'text'
      }),
      invalidatesTags: ['IDENTIFICATION_FILES']
    }),
    deleteIdentificationFile: builder.mutation<void, IIdentificationFile['id']>({
      query: (id) => ({
        url: `/api/identificationFile/${id}`,
        method: 'DELETE',
        responseHandler: 'text'
      }),
      invalidatesTags: ['IDENTIFICATION_FILES']
    }),
    getIdentificationFileTypes: builder.query<IdentificationFilesTypes[], void>({
      query: () => ({
        url: '/api/identificationFileTypes',
        method: 'GET'
      })
    })
  })
})

export const {
  useGetIdentificationFilesQuery,
  usePostIdentificationFileMutation,
  useDeleteIdentificationFileMutation,
  useGetIdentificationFileTypesQuery
} = identificationFilesApi

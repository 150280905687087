import {
  IUserDetails, IBasket, IBasketItem, ProductType, IPriceSet
} from '../../types'
import { DrawerProps } from '../../components/Drawer/Drawer.types'

export enum BasketChangeEvents {
  ADD = 'ADD',
  EDIT = 'EDIT',
  DELETE = 'DELETE'
}

export interface PurchaseSidebarTypes {
  user?: Partial<IUserDetails>
  open?: boolean
  basket?: IBasket
  isLoading: boolean
  onOpen?: () => void
  onClose?: () => void
  onBasketChange: (event: BasketChangeEvents, params: Partial<IBasketItem>) => void
  onGoToBasketClick: () => void
  recommendedProductsData?: ProductType[]
  priceSet?: IPriceSet
  onboardingPartnerText?: string | boolean
  serviceUrls?: {
    applicationUrl: string
    paymentUrl: string
  }
  drawerExtraProps?: DrawerProps['drawerExtraProps']
}

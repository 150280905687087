import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithAuth } from '@store/middleware'
import { ecommerceUrl } from '@constants'
import {
  ICart, IDocument, ILineItem, IOrder, IProduct, TProductPurchaseType, TProductsType, IProductVariant
} from '@types'
import { IPartner, IUserDetails } from '@testavivadk/common-tools/types'
import { documentsApi } from './documents'

interface IDocumentPaidTokenData {
  id: number,
  user_id: IUserDetails['id'],
  purchase_id: number,
  product_type: TProductsType,
  purchase_type: TProductPurchaseType,
  consumed_by: IDocument['id'],
  consumed_at: string,
  created_at: string,
  updated_at: string
}

export const ECOMMERCE_API_REDUCER_KEY = 'ecommerceApi'
export const ecommerceApi = createApi({
  reducerPath: ECOMMERCE_API_REDUCER_KEY,
  tagTypes: ['EcommerceProducts', 'EcommerceCart', 'EcommerceOrders', 'EcommerceDocumentPaidToken'],
  baseQuery: baseQueryWithAuth({ baseUrl: `${ecommerceUrl}/api/v1` }),
  endpoints: (builder) => ({
    getOrders: builder.query<IOrder[], { userId: IUserDetails['id'] }>({
      query: ({ userId }) => ({
        url: `/users/${userId}/orders`,
        method: 'GET'
      }),
      providesTags: ['EcommerceOrders']
    }),
    getProducts: builder.query<IProduct[], { partner?: IPartner, userId: IUserDetails['id'] }>({
      query: ({ partner, userId }) => ({
        url: `/products?userId=${userId}${
          partner != null ? `&partner=${partner}` : ''
        }`,
        method: 'GET'
      }),
      providesTags: ['EcommerceProducts']
    }),
    getCart: builder.query<ICart, { userId: IUserDetails['id'] }>({
      query: ({ userId }) => ({
        url: `/users/${userId}/cart`,
        method: 'GET'
      }),
      providesTags: ['EcommerceCart']
    }),
    getItemsInCart: builder.query<{ items: number }, { userId: IUserDetails['id'] }>({
      query: ({ userId }) => ({
        url: `/users/${userId}/cart/items`,
        method: 'GET'
      }),
      providesTags: ['EcommerceCart']
    }),
    // eslint-disable-next-line max-len
    updateLineItemInCart: builder.mutation<ICart, { userId: IUserDetails['id'], cartId: ICart['cartId'], lineItemId: ILineItem['lineId'], variantId: IProductVariant['variantId'], quantity: number }>({
      query: ({
        userId, cartId, lineItemId, variantId, quantity
      }) => ({
        url: `/users/${userId}/cart`,
        method: 'PATCH',
        body: {
          cartId,
          lines: [
            {
              quantity,
              merchandiseId: variantId,
              id: lineItemId
            }
          ]
        },
        headers: { 'Content-Type': 'application/json' }
      }),
      async onQueryStarted({ userId, ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          ecommerceApi.util.updateQueryData('getCart', { userId }, (draft) => {
            Object.assign(draft, patch)
          })
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
      invalidatesTags: ['EcommerceCart']
    }),
    removeLineItemFromCart: builder.mutation<ICart, { userId: IUserDetails['id'], cartId: ICart['cartId'], lineItemId: ILineItem['lineId'] }>({
      query: ({ userId, cartId, lineItemId }) => ({
        url: `/users/${userId}/cart`,
        method: 'DELETE',
        body: {
          cartId,
          lineIds: [lineItemId]
        },
        headers: { 'Content-Type': 'application/json' }
      }),
      invalidatesTags: ['EcommerceCart']
    }),
    createCart: builder.mutation<ICart, { userId: IUserDetails['id'], userEmail: IUserDetails['email'], variantId: IProductVariant['variantId'] }>({
      query: ({ userId, variantId, userEmail }) => ({
        url: `/users/${userId}/cart`,
        method: 'POST',
        body: {
          email: userEmail,
          lines: [
            {
              quantity: 1,
              merchandiseId: variantId
            }
          ]
        },
        headers: { 'Content-Type': 'application/json' }
      }),
      invalidatesTags: ['EcommerceCart']
    }),
    addLineItemToCart: builder.mutation<ICart, { userId: IUserDetails['id'], cartId: ICart['cartId'], variantId: IProductVariant['variantId'] }>({
      query: ({ userId, cartId, variantId }) => ({
        url: `/users/${userId}/cart`,
        method: 'PUT',
        body: {
          cartId,
          lines: [
            {
              quantity: 1,
              merchandiseId: variantId
            }
          ]
        },
        headers: { 'Content-Type': 'application/json' }
      }),
      invalidatesTags: ['EcommerceCart']
    }),
    getDocumentPaidTokens: builder.query<IDocumentPaidTokenData[], { userId: IUserDetails['id'], consumed?: boolean } >({
      query: ({ userId, consumed }) => ({
        url: `/productToken?userId=${userId}${consumed != null ? `&consumed=${consumed}` : ''}`,
        method: 'GET'
      }),
      providesTags: ['EcommerceDocumentPaidToken']
    }),
    getIsDocumentPaid: builder.query<IDocumentPaidTokenData, { documentId: IDocument['id'] }>({
      query: ({ documentId }) => ({
        url: `/productToken/isPaid?documentId=${documentId}`,
        method: 'GET'
      })
    }),
    consumeProductToken: builder.mutation<unknown, { userId: IUserDetails['id'], productType: TProductsType, purchaseType: TProductPurchaseType, consumedBy: IDocument['id'] }>({
      query: ({
        userId, productType, purchaseType, consumedBy
      }) => ({
        url: '/productToken/consume',
        method: 'PUT',
        body: {
          userId,
          productType,
          purchaseType,
          consumedBy
        }
      }),
      invalidatesTags: ['EcommerceDocumentPaidToken'],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          dispatch(documentsApi.util.invalidateTags(['MY_PAGE_DOCUMENTS']))
        } catch (error) {
          // Handle error if needed
        }
      }
    })
  })
})

export const {
  useGetOrdersQuery,
  useGetProductsQuery,
  useGetCartQuery,
  useLazyGetIsDocumentPaidQuery,
  useGetIsDocumentPaidQuery,
  useGetDocumentPaidTokensQuery,
  useConsumeProductTokenMutation,
  useCreateCartMutation,
  useAddLineItemToCartMutation,
  useUpdateLineItemInCartMutation,
  useRemoveLineItemFromCartMutation
} = ecommerceApi

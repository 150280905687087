import { FC, PropsWithChildren } from 'react'
import { extractTextFromReact } from '../../../utils/extractTextFromReact'

import { OptionProps } from '../Select.types'

const NativeOption: FC<PropsWithChildren<OptionProps>> = ({
  value,
  children,
  ...props
}) => (
  <option
    {...props}
    value={value}
  >
    {extractTextFromReact(children)}
  </option>
)

export default NativeOption

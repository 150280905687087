import {
  FC, lazy, Suspense, useEffect, useState
} from 'react'

import { useGetUserDetailsQuery } from '@store/services/user'
import { RootState } from '@store/rootReducer'
import { useSelector, shallowEqual } from 'react-redux'
import { getUrlParam } from '@frontend-components/utils/urls'
import { authTokenName } from '@constants'
import { getStorageItem } from '@frontend-components/utils/storage'

import { REFERENCE_AUTH_MODAL_STORAGE_KEY } from './components/ReferenceAuthenticationModal/ReferenceAuthenticationModal.types'
import UnlockDocumentAfterPaymentModal from './components/EcommerceModals/UnlockDocumentAfterPaymentModal'

const PartnersModal = lazy(() => import(/* webpackChunkName: "PartnersModal" */ './components/PartnersModal'))
const IdentityVerificationModal = lazy(() => import(/* webpackChunkName: "IdentityVerificationModal" */ './components/IdentityVerificationModal'))
const AuthenticationModal = lazy(() => import(/* webpackChunkName: "AuthenticationModal" */ './components/AuthenticationModal'))
const ConditionsModal = lazy(() => import(/* webpackChunkName: "ConditionsModal" */ './components/ConditionsModal'))
const ReferenceAuthenticationModal = lazy(() => import(/* webpackChunkName: "ReferenceAuthenticationModal" */ './components/ReferenceAuthenticationModal'))
const DownloadBlobModal = lazy(() => import(/* webpackChunkName: "DownloadBlobModal" */ './components/DownloadBlobModal'))
const DocumentPaidTokenModal = lazy(() => import (/* webpackChunkName: "DocumentPaidTokenModal" */ './components/EcommerceModals/DocumentPaidTokenModal'))

enum OnLoadModals {
  CONDITIONS = 'CONDITIONS',
  REFERENCE_AUTHENTICATION = 'REFERENCE_AUTHENTICATION'
}

const Modals: FC = () => {
  const {
    isPartnersModalOpen,
    identityVerificationModalState,
    authenticationModalData,
    downloadBlobData,
    isDocumentPaidTokenModalOpen
  } = useSelector((state: RootState) => ({
    isPartnersModalOpen: state.modal.isPartnersModalOpen,
    identityVerificationModalState: state.modal.identityVerificationModalState,
    authenticationModalData: state.modal.authenticationModal,
    downloadBlobData: state.modal.downloadBlobData,
    isDocumentPaidTokenModalOpen: state.modal.documentPaidTokenModalState.isOpen
  }), shallowEqual)

  const referenceAuthenticationToken = getUrlParam(window.location.href, authTokenName)

  const [visibleOnLoadModal, setVisibleOnLoadModal] = useState<OnLoadModals | null>(null)
  const { data: userDetailsData, refetch: refetchUserDetails } = useGetUserDetailsQuery()

  const onCloseReferenceAuthenticationModal = () => {
    setVisibleOnLoadModal(null)
    refetchUserDetails()
  }

  useEffect(() => {
    if (userDetailsData != null && userDetailsData.authenticated) {
      getVisibleOnLoadModal()
    }
  }, [userDetailsData])

  return (
    <Suspense fallback="">
      {isPartnersModalOpen ? <PartnersModal /> : null}
      {identityVerificationModalState.isOpen ? (
        <IdentityVerificationModal redirectUrl={identityVerificationModalState.redirectUrl} />
      ) : null}
      {authenticationModalData != null ? <AuthenticationModal data={authenticationModalData} /> : null}
      {isDocumentPaidTokenModalOpen ? <DocumentPaidTokenModal /> : null}
      {visibleOnLoadModal === OnLoadModals.CONDITIONS ? (
        <ConditionsModal
          previouslyConditionsAccepted={userDetailsData?.previouslyConditionsAccepted}
          onClose={() => setVisibleOnLoadModal(null)}
        />
      ) : null}
      {visibleOnLoadModal === OnLoadModals.REFERENCE_AUTHENTICATION && referenceAuthenticationToken != null && userDetailsData != null ? (
        <ReferenceAuthenticationModal
          token={referenceAuthenticationToken}
          onClose={onCloseReferenceAuthenticationModal}
        />
      ) : null}
      { downloadBlobData !== undefined && <DownloadBlobModal data={downloadBlobData} /> }
      <UnlockDocumentAfterPaymentModal />
    </Suspense>
  )

  function getVisibleOnLoadModal() {
    if (userDetailsData == null || !userDetailsData?.authenticated) return

    if (referenceAuthenticationToken != null && getStorageItem(`${REFERENCE_AUTH_MODAL_STORAGE_KEY}_${userDetailsData.id}`) == null) {
      setVisibleOnLoadModal(OnLoadModals.REFERENCE_AUTHENTICATION)
      return
    }

    if (!userDetailsData.conditionsAccepted) {
      setVisibleOnLoadModal(OnLoadModals.CONDITIONS)
    }
  }
}

export default Modals

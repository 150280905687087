import { FC } from 'react'
import classnames from 'classnames'

import { SpinnerProps } from './Spinner.types'
import styles from './Spinner.module.scss'

const Spinner: FC<SpinnerProps> = ({
  size = 'medium',
  className,
  position = 'static',
  withOverlay = false,
  ...props
}) => (
  <div {...props} className={classnames(styles.wrapper, styles[position], className, { [styles.overlay]: withOverlay })}>
    <div className={classnames(styles.spinner, styles[size])} />
  </div>
)

export default Spinner

import { useState, useEffect, useCallback } from 'react'
import { useEventListener } from './useEventListener'

export function useWindowSize(): { width: number, height: number } {
  const [windowSize, setWindowSize] = useState({ width: 0, height: 0 })

  const handleWindowResize = useCallback(() => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight
    })
  }, [])

  useEffect(() => {
    handleWindowResize()
  }, [])

  useEventListener('resize', handleWindowResize)

  return windowSize
}

import { FC } from 'react'
import PurchaseSidebar from './PurchaseSidebar'
import ErrorBoundary from '@frontend-components/components/ErrorBoundary'

const PurchaseSidebarFeatures: FC = () => (
  <ErrorBoundary hideErrorMessage>
    <PurchaseSidebar />
  </ErrorBoundary>
)

export default PurchaseSidebarFeatures

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface CartState {
  isCartSidebarOpen: boolean
}

const initialState: CartState = { isCartSidebarOpen: false }

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setCartSidebarOpen(state, action: PayloadAction<CartState['isCartSidebarOpen']>) {
      state.isCartSidebarOpen = action.payload
    }
  }
})

export const { setCartSidebarOpen } = cartSlice.actions
export default cartSlice.reducer

import { type FC, type ChangeEvent } from 'react'

import { useGetProductsQuery, useUpdateLineItemInCartMutation } from '@store/services/ecommerce'
import { useTranslation } from 'react-i18next'

import {
  Person as PersonIcon,
  PersonSearch as PersonSearchIcon,
  HeadsetMic as HeadsetMicIcon
} from '@mui/icons-material'
import Box from '@mui/joy/Box'
import Chip from '@mui/joy/Chip'
import Radio from '@mui/joy/Radio'
import RadioGroup from '@mui/joy/RadioGroup'

import { type ILineItem } from 'src/types/cart.types'
import { type TProductPurchaseType } from '@types'
import { useGetUserDetailsQuery } from '@store/services/user'

interface VariantSelectProps {
  lineItem: ILineItem
  cartId: string
}

const VariantSelect: FC<VariantSelectProps> = ({
  lineItem: {
    lineId, purchaseType, productType, quantity
  },
  cartId
}) => {
  const { data: userDetailsData } = useGetUserDetailsQuery()
  const { data: productsData } = useGetProductsQuery({ partner: userDetailsData?.partners[0], userId: Number(userDetailsData?.id) })
  const [updateLineItem, { isLoading: isUpdateLineItemPending }] = useUpdateLineItemInCartMutation()

  const { t } = useTranslation()

  const variants = productsData?.find((product) => product.productType === productType)
    ?.variants ?? []

  const handleChangeVariant = async (event: ChangeEvent<HTMLInputElement>) => {
    const variantId = event.target.value
    await updateLineItem({
      lineItemId: lineId,
      cartId,
      userId: Number(userDetailsData?.id),
      variantId,
      quantity
    })
  }

  return (
    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
      <div>
        <RadioGroup
          name="best-movie"
          aria-labelledby="best-movie"
          orientation="horizontal"
          sx={{ flexWrap: 'wrap', gap: 1 }}
        >
          {variants.map((variant) => {
            const isChecked = !isUpdateLineItemPending && purchaseType === variant.purchaseType

            return (
              <Chip
                key={variant.variantId}
                variant="plain"
                color={isChecked ? 'primary' : 'neutral'}
                startDecorator={getVariantIcon(variant.purchaseType)}
              >
                <Radio
                  variant="outlined"
                  color={isChecked ? 'primary' : 'neutral'}
                  disableIcon
                  overlay
                  label={t(`products.purchaseType.${variant.purchaseType}`)}
                  value={variant.variantId}
                  checked={isChecked}
                  onChange={handleChangeVariant}
                />
              </Chip>
            )
          })}
        </RadioGroup>
      </div>
    </Box>
  )
}

function getVariantIcon(purchaseType: TProductPurchaseType) {
  if (purchaseType === 'basic') {
    return (
      <PersonIcon
        sx={{ zIndex: 1, pointerEvents: 'none', fontSize: '1rem' }}
      />
    )
  }
  if (purchaseType === 'review') {
    return (
      <PersonSearchIcon
        sx={{ zIndex: 1, pointerEvents: 'none', fontSize: '1rem' }}
      />
    )
  }
  return (
    <HeadsetMicIcon
      sx={{ zIndex: 1, pointerEvents: 'none', fontSize: '1rem' }}
    />
  )
}

export default VariantSelect

import React, { useMemo } from 'react'
import { AnalyticsBrowser } from '@segment/analytics-next'
import { AnalyticsContext, AnalyticsContextProps } from './AnalyticsContext'

interface AnalyticsProviderProps {
  children: React.ReactNode
}

const writeKey = import.meta.env.VITE_REACT_APP_SEGMENT_WRITE_KEY ?? ''
export const AnalyticsProvider: React.FC<AnalyticsProviderProps> = ({
  children
}) => {
  const value: AnalyticsContextProps = useMemo(() => ({ analytics: AnalyticsBrowser.load({ writeKey }) }), [])

  return (
    <AnalyticsContext.Provider value={value}>
      {children}
    </AnalyticsContext.Provider>
  )
}

import React, {
  FC,
  useState,
  useCallback,
  PropsWithChildren
} from 'react'
import classnames from 'classnames'

import { useContext } from '../context'
import { extractTextFromReact } from '../../../utils/extractTextFromReact'

import InputWrapper from '../../../helperComponents/InputWrapper'

import { SelectProps } from '../Select.types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons'
import styles from '../Select.module.scss'

const NativeSelect: FC<PropsWithChildren<SelectProps>> = ({
  label,
  name,
  disabled,
  placeholder,
  children,
  selectExtraProps,
  ...props
}) => {
  const [isFocused, setFocused] = useState(false)
  const { selectedValue, setSelectedValue } = useContext()

  const areaLabelText = props['aria-label'] ?? extractTextFromReact(label ?? placeholder)
  const isWithValue = selectedValue.length > 0 && selectedValue !== placeholder
  const isHidden = selectedValue.length === 0 && placeholder == null
  const valueToShow = selectedValue !== placeholder ? selectedValue : ''

  const handleChange = useCallback((event: React.ChangeEvent<HTMLSelectElement>) => {
    if (disabled) {
      return
    }
    setSelectedValue(event.target.value)
  }, [disabled])

  const placeholderElement = placeholder && placeholder.length > 0 ? (
    <option value={placeholder}>
      {placeholder}
    </option>
  ) : null

  return (
    <InputWrapper
      {...props}
      name={name}
      value={valueToShow}
      isFocused={isFocused}
      label={label}
      placeholder={placeholder}
      rightIcon={<FontAwesomeIcon icon={faChevronDown} />}
      disabled={disabled}
    >
      <select
        {...selectExtraProps}
        value={valueToShow}
        aria-label={areaLabelText}
        name={name}
        onChange={handleChange}
        disabled={disabled}
        className={classnames(styles.select, {
          [styles.withValue]: isWithValue,
          [styles.hidden]: isHidden
        }, styles.nativeSelect)}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
      >
        {placeholderElement}
        {children}
      </select>
    </InputWrapper>
  )
}

export default NativeSelect

const acceptedFileExtensions = ['JPG', 'JPEG', 'PNG', 'PDF', 'HEIC']

const acceptedFileExtensionsString = acceptedFileExtensions.map((extension) => `.${extension.toLowerCase()}`).join(',')

const isAcceptedFileExtension = (extension: string) => {
  const extensionParts = extension.split('/')
  return extensionParts.length === 2 && acceptedFileExtensions.includes(extensionParts[1].toUpperCase())
}

export { isAcceptedFileExtension, acceptedFileExtensions, acceptedFileExtensionsString }

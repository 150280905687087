import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { DialogTitle, DialogContent, Alert } from '@mui/material'

import { HasUnlockedContentProps } from './HasUnlockedContent.types'

const HasUnlockedContent: FC<HasUnlockedContentProps> = ({ consumeError, loadTokensError }) => {
  const { t } = useTranslation()
  const isError = consumeError || loadTokensError

  return (
    <>
      <DialogTitle>{t('unlockDocumentAfterPaymentModal.hasUnlockedContent.title')}</DialogTitle>
      <DialogContent>
        {isError ? (
          <>
            {consumeError && <Alert severity="error">{t('unlockDocumentAfterPaymentModal.hasUnlockedContent.consumeError')}</Alert>}
            {loadTokensError && <Alert severity="error">{t('unlockDocumentAfterPaymentModal.hasUnlockedContent.loadTokensError')}</Alert>}
          </>
        ) : (
          <p>{t('unlockDocumentAfterPaymentModal.hasUnlockedContent.content')}</p>
        )}
      </DialogContent>
    </>
  )
}

export default HasUnlockedContent

import { useCallback } from 'react'
import useAnalyticsContext from './useAnalyticsContext'
import { EventProperties, UserTraits } from '@segment/analytics-next'

const useAnalytics = () => {
  const { analytics } = useAnalyticsContext()

  const page = useCallback(
    (name: string, category = 'App', properties?: EventProperties) => {
      try {
        analytics.page(category, name, properties)
      } catch (error) {
        console.error(error)
      }
    },
    [analytics]
  )

  const track = useCallback(
    (event: string, properties?: EventProperties) => {
      try {
        analytics.track(event, properties)
      } catch (error) {
        console.error(error)
      }
    },
    [analytics]
  )

  const identify = useCallback(
    (userId: string, traits?: UserTraits) => {
      try {
        analytics.identify(userId, traits)
      } catch (error) {
        console.error(error)
      }
    },
    [analytics]
  )

  return {
    page,
    track,
    identify
  }
}

export default useAnalytics

import { FC, useEffect, useState } from 'react'

import { getUrlParam } from '@frontend-components/utils/urls'

import Alert from '@frontend-components/components/Alert'

import { AlertType } from '@frontend-components/components/Alert/Alert.types'
import { useLocation } from 'react-router-dom'

const FlashMessage: FC = () => {
  const { pathname } = useLocation()
  const [type, setType] = useState<AlertType | null>(null)
  const [message, setMessage] = useState('')

  useEffect(() => {
    const flashSuccess = getUrlParam(window.location.href, 'flashSuccess')
    const flashWarning = getUrlParam(window.location.href, 'flashWarning')
    const flashError = getUrlParam(window.location.href, 'flashError')

    if (flashSuccess != null) {
      setType('success')
      setMessage(flashSuccess)
    } else if (flashWarning != null) {
      setType('warning')
      setMessage(flashWarning)
    } else if (flashError != null) {
      setType('error')
      setMessage(flashError)
    }
  }, [pathname])

  return type != null ? <Alert data-cy={`flash-message-alert-${type}`} type={type} onClose={() => setType(null)}><p data-cy="flash-message-content">{message}</p></Alert> : null
}

export default FlashMessage

import { useEffect } from 'react'

export function useDisablePageScrolling(shouldDisable: boolean, skip?: boolean): void {
  useEffect(() => {
    if (shouldDisable && !skip) {
      document.body.style.overflow = 'hidden'
      document.body.style.height = '100%'
    } else {
      document.body.style.overflow = 'visible'
      document.body.style.height = ''
    }

    return () => {
      document.body.style.overflow = 'visible'
      document.body.style.height = ''
    }
  }, [shouldDisable])
}

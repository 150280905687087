import { FC, useState, useEffect } from 'react'
import classnames from 'classnames'

import { useThrottle } from '../../hooks/useThrottle'

import Message from '../../components/Message'

import { InputWrapperProps } from './InputWrapper.types'
import styles from './InputWrapper.module.scss'

const animationTimeoutMs = 200

const InputWrapper: FC<InputWrapperProps> = ({
  kind = 'bordered',
  inputType = 'input',
  inputSize = 'medium',
  label,
  fixedLabel = false,
  value = '',
  disabled,
  isError = false,
  errorMessage,
  message,
  placeholder,
  name,
  classNames,
  leftIcon,
  rightIcon,
  isFocused,
  children
}) => {
  const [isPlaceholderVisible, setPlaceholderVisible] = useState(value.length > 0)
  const [isFullWidth, setFullWidth] = useState(false)
  const inputLabel = label ?? placeholder
  const noAnimation = label != null && placeholder != null

  useEffect(() => {
    if (noAnimation || fixedLabel || value.length > 0 || isFocused) {
      setPlaceholderVisible(false)
      setFullWidth(true)
    } else {
      setPlaceholderVisible(true)
      setFullWidth(false)
    }
  }, [value, fixedLabel, isFocused, noAnimation])

  const leftIconElement = leftIcon != null ? <span className={styles.icon}>{leftIcon}</span> : null
  const rightIconElement = rightIcon != null ? <span className={styles.icon}>{rightIcon}</span> : null
  const errorMessageElement = errorMessage ? <Message className={styles.errorMessage} type="error">{errorMessage}</Message> : null
  const messageElement = message ? <Message className={styles.message}>{message}</Message> : null
  const throttledFullWidth = useThrottle(isFullWidth, animationTimeoutMs)

  return (
    <div className={classnames(styles.wrapper, classNames?.wrapper)}>
      <div
        className={classnames(styles.inputWrapper, styles[inputSize], styles[inputType], styles[kind], {
          [styles.focused]: isFocused,
          [styles.disabled]: disabled,
          [styles.error]: isError,
          [styles.withLeftIcon]: leftIcon != null,
          [styles.withRightIcon]: rightIcon != null
        })}
      >
        {leftIconElement}
        {children}
        {rightIconElement}
        <fieldset className={styles.fieldset}>
          <label
            className={classnames(styles.label, {
              [styles.centered]: isPlaceholderVisible,
              [styles.fullWidth]: throttledFullWidth
            })}
            htmlFor={name}
          >
            {inputLabel}
          </label>
          <legend
            className={classnames(styles.legend, {
              [styles.visible]: !isPlaceholderVisible,
              [styles.smaller]: inputLabel != null && String(inputLabel).length > 7
            })}
          >
            {inputLabel}
          </legend>
        </fieldset>
      </div>
      {errorMessageElement}
      {messageElement}
    </div>
  )
}

export default InputWrapper

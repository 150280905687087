import { FC } from 'react'

import { DeviceDetection } from '../../utils/deviceDetection'
import { useContext } from './context'

import NativeOption from './components/NativeOption'
import StyledOption from './components/StyledOption'

import { OptionProps } from './Select.types'

const isTouchDevice = DeviceDetection.isTouchDevice()

const Option: FC<OptionProps> = ({ ...props }) => {
  const { isNativeSelect } = useContext()
  const optionElement = isTouchDevice || isNativeSelect ? <NativeOption {...props} /> : <StyledOption {...props} />

  return optionElement
}

export default Option

import React, { memo } from 'react'
import Context from './Context'
import { ContextTypes } from './types'

const Consumer: React.FC<any> = ({ children }) => (
  <Context.Consumer>
    {(props: ContextTypes) => React.cloneElement(children, { props })}
  </Context.Consumer>
)

export default memo(Consumer)

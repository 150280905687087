import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithAuth } from '@store/middleware'

import { IPartner } from '@frontend-components/types'
import { applicationUrl } from '@constants'

export const PARTNER_API_REDUCER_KEY = 'partnerApi'
export const partnerApi = createApi({
  reducerPath: PARTNER_API_REDUCER_KEY,
  baseQuery: baseQueryWithAuth({ baseUrl: applicationUrl }),
  endpoints: (builder) => ({
    getPartners: builder.query<IPartner[], void>({
      query: () => ({
        url: '/api/partners?priority=1',
        method: 'GET'
      })
    })
  })
})

export const {
  useGetPartnersQuery,
  useLazyGetPartnersQuery
} = partnerApi

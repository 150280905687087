export enum AuthenticationSteps {
  EMAIL_VERIFICATION = 'EMAIL_VERIFICATION',
  ONBOARD_WITH_INFO = 'ONBOARD_WITH_INFO',
  USER_SELECT = 'USER_SELECT',
  LOGIN = 'LOGIN',
  RESET_PASSWORD = 'RESET_PASSWORD',
  USER_INFO = 'USER_INFO',
  PARTNER_SELECT = 'PARTNER_SELECT',
  CONSENTS = 'CONSENTS',
  VALIDATION = 'VALIDATION'
}

export const nonePartnerId = 'none'

export enum SubmitActions {
  LOGIN = 'LOGIN',
  RESET_PASSWORD = 'RESET_PASSWORD',
  CREATE_USER = 'CREATE_USER'
}

export const loginRedirectKey = 'login_redirect'

export class DeviceDetection {
  static isTouchDevice(): boolean {
    return matchMedia('(hover: none)').matches
  }

  static isMacintosh(): boolean {
    return !!navigator.userAgent.match(/Mac/i)
  }

  static isBot(): boolean {
    return /bot|googlebot|crawler|spider|robot|crawling/i.test(navigator.userAgent)
  }
}

import { getWordpressApiUrl } from '../utils/urls'

export * from './purchaseTypes'
export * from './products'
export * from './user'
export * from './advisorsTypes'
export * from './signatureTypes'
export * from './registrationTypes'

export const authCookieName = 'testaviva_app'
export const authAdminCookieName = 'TV_QA_APPLICATION_SESSID'
export const authHeaderName = 'X-AUTH-TOKEN'

export const recaptchaId = '6LeCm6gUAAAAAFPr1OwCh4Hh6bK1g9vy7XoLl97_'

export const recaptchaScriptUrl = `https://www.google.com/recaptcha/api.js?render=${recaptchaId}`

export const applicationSpaRoutes = {
  onboardingIndex: '/onboarding',
  onboardingValues: '/onboarding/værdier',
  onboardingScore: '/onboarding/anbefaling',
  profileIndex: '/profile',
  profileEdit: '/profile/edit',
  profileBookings: '/profile/bookings',
  profilePayments: '/profile/payments',
  profileChangePassword: '/profile/change-password',
  resendActivationMail: '/gensend-aktiveringsmail',
  consentBorgerDK: '/samtykke-borger-dk',
  createDocument: '/opret-dokument',
  basket: '/basket',
  completePurchase: '/checkout-completed',
  receipt: '/receipt',
  login: '/login',
  userActivation: '/user-activation',
  notifications: '/notifications',
  myOverview: '/mit-overblik',
  myDocuments: '/my-documents',
  approveDocument: '/my-documents/approve',
  mitIdVerification: '/opret-dokument/adgang',
  uploadId: '/upload-id'
}

export const applicationRoutes = { dashboard: '/min-side' }

export enum DocumentStatuses {
  DRAFT = 'draft',
  WAITING_FOR_SIGNATURE = 'waiting-for-signature',
  WAITING_FOR_REGISTRATION = 'waiting-for-registration',
  WAITING_FOR_CONFIRMATION = 'waiting-for-confirmation',
  WAITING_FOR_TESTAVIVA_REGISTRATION = 'waiting-for-testaviva-registration',
  WAITING_FOR_MEETING = 'waiting-for-meeting',
  WAITING_FOR_PAYMENT = 'waiting-for-payment',
  UPDATE_REQUIRED = 'update-required',
  UPDATE_RECOMMENDED = 'update-recommended',
  SIGNED = 'signed'
}

export enum IdentificationFilesTypes {
  PASSPORT = 'passport',
  DRIVERS_LICENSE = 'drivers_license',
  OTHER = 'other'
}

export const wordPressApiUrl = getWordpressApiUrl() // process.env.NODE_ENV === 'development' ? 'https://shared-frontend.development.testaviva.dk' : getServiceUrlFor(window.location.href, 'frontend')

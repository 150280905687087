import { useMedia } from './useMedia'

export enum BREAKPOINT {
  xs = 576,
  sm = 768,
  md = 992,
  lg = 1200
}

enum ORIENTATION {
  landscape = 'landscape',
  portrait = 'portrait',
}

type UseResponsivenessType = {
  orientation: keyof typeof ORIENTATION
  below: {
    xs: boolean
    sm: boolean
    md: boolean
    lg: boolean
  },
  above: {
    xs: boolean
    sm: boolean
    md: boolean
    lg: boolean
  }
}

export function useResponsiveness(): UseResponsivenessType {
  const isLandscape = useMedia('(orientation: landscape)')
  const isBelowXs = useMedia(`(max-width: ${BREAKPOINT.xs - 1}px)`)
  const isBelowSm = useMedia(`(max-width: ${BREAKPOINT.sm - 1}px)`)
  const isBelowMd = useMedia(`(max-width: ${BREAKPOINT.md - 1}px)`)
  const isBelowLg = useMedia(`(max-width: ${BREAKPOINT.lg - 1}px)`)
  const isAboveXs = useMedia(`(min-width: ${BREAKPOINT.xs}px)`)
  const isAboveSm = useMedia(`(min-width: ${BREAKPOINT.sm}px)`)
  const isAboveMd = useMedia(`(min-width: ${BREAKPOINT.md}px)`)
  const isAboveLg = useMedia(`(min-width: ${BREAKPOINT.lg}px)`)

  return {
    orientation: isLandscape ? ORIENTATION.landscape : ORIENTATION.portrait,
    below: {
      xs: isBelowXs,
      sm: isBelowSm,
      md: isBelowMd,
      lg: isBelowLg
    },
    above: {
      xs: isAboveXs,
      sm: isAboveSm,
      md: isAboveMd,
      lg: isAboveLg
    }
  }
}

import { FC, PropsWithChildren } from 'react'

import styles from './HeaderWrapper.module.scss'
import LogoFull from '../../public/icons/logo-full.svg'
import { HeaderWrapperProps } from './HeaderWrapper.types'

const HeaderWrapper: FC<PropsWithChildren<HeaderWrapperProps>> = ({ children }) => (
  <>
    <div className={styles.header}>
      <div className={`container ${styles.headerCenter}`}>
        <LogoFull />
        {children}
      </div>
    </div>
    <div className={styles.headerSpacer} />
  </>
)

export default HeaderWrapper

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface AlertState {
  fileDownloadError: boolean
}

const initialState: AlertState = {
  fileDownloadError: false
}

const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    setFileDownloadErrorState(state, action: PayloadAction<AlertState['fileDownloadError']>) {
      state.fileDownloadError = action.payload
    }
  }
})

export const {
  setFileDownloadErrorState
} = alertSlice.actions
export default alertSlice.reducer

import * as Types from './types'

export const initValues: Types.ContextTypes = {
  selectedValue: '',
  isNativeSelect: true,
  highlightedItemIndex: -1,
  isMouseHighlightDisabled: false,
  setSelectedValue: (value: Types.SelectedValueType) => value,
  setNativeSelect: (value: Types.IsNativeSelectType) => value,
  setHighlightedItemIndex: (index: Types.HighlightedItemIndexType) => index,
  setMouseHighlightDisabled: (value: Types.IsMouseHighlightDisabledType) => value
}

import { useEffect, useState } from 'react'

export function useMedia(query: string): boolean {
  const [state, setState] = useState(window.matchMedia(query).matches)

  useEffect(() => {
    const mediaQuery = window.matchMedia(query)

    const onChange = () => {
      setState(mediaQuery.matches)
    }

    mediaQuery.addEventListener('change', onChange)
    setState(mediaQuery.matches)

    return () => mediaQuery.removeEventListener('change', onChange)
  }, [query])

  return state
}

import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithAuth } from '@store/middleware'

import { ProductType, IPriceSet, IProductInfo } from '@frontend-components/types'
import { IOnboardingData } from '@store/slices/onboardingSlice'
import { paymentUrl, applicationUrl } from '@constants'
import { IRecommendation } from '@types'

export const PRODUCTS_API_REDUCER_KEY = 'productsApi'
export const productsApi = createApi({
  reducerPath: PRODUCTS_API_REDUCER_KEY,
  baseQuery: baseQueryWithAuth({}),
  endpoints: (builder) => ({
    getProductPrices: builder.query<IPriceSet, string | null>({
      query: (partner) => ({
        url: `${paymentUrl}/api/price-set${partner != null ? `?partner=${partner}` : ''}`,
        method: 'GET'
      })
    }),
    getOnboardingRecommendedProducts: builder.mutation({
      query: (onboardingData: IOnboardingData) => ({
        url: `${applicationUrl}/api/score/recommended-products`,
        method: 'POST',
        body: { users: [onboardingData] }
      })
    }),
    getUserRecommendedProducts: builder.query<ProductType[], unknown>({
      query: () => ({
        url: `${applicationUrl}/api/score/recommended-products`,
        method: 'GET'
      })
    }),
    getAllProducts: builder.query<IProductInfo[], void>({
      query: () => ({
        url: `${applicationUrl}/api/get-all-products`,
        method: 'GET'
      })
    }),
    getRecommendations: builder.query<IRecommendation[], void>({
      query: () => ({
        url: `${applicationUrl}/api/products/recommendations`,
        method: 'GET'
      })
    })
  })
})

export const {
  useGetProductPricesQuery,
  useLazyGetProductPricesQuery,
  useGetOnboardingRecommendedProductsMutation,
  useGetUserRecommendedProductsQuery,
  useLazyGetUserRecommendedProductsQuery,
  useGetAllProductsQuery,
  useGetRecommendationsQuery
} = productsApi

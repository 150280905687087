import { useEffect } from 'react'

import { useLocation, Outlet } from 'react-router-dom'

import FlashMessage from '@features/FlashMessage'
import Header from '@features/Header'
import Modals from '@features/Modals'
import PurchaseSidebar from '@features/PurchaseSidebar'
import OpenPdfFileModal from '@features/OpenPdfFileModal/OpenPdfFileModal'
import Footer from '@frontend-components/components/Footer'
import SharedEmailCheck from '@features/SharedEmailCheck'
import CartSidebar from '@features/CartSidebar'

const RootLayout = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    document.documentElement.scrollTo({ top: 0, left: 0 })
  }, [pathname])

  return (
    <>
      <Header />
      <PurchaseSidebar />
      <CartSidebar />
      <Outlet />
      <Footer />
      <Modals />
      <FlashMessage />
      <OpenPdfFileModal />
      <SharedEmailCheck />
    </>
  )
}

export default RootLayout

import { createApi } from '@reduxjs/toolkit/query/react'
import { IOnboardingData } from '@store/slices/onboardingSlice'
import { baseQueryWithAuth } from '@store/middleware'
import { applicationUrl } from '@constants'

export const ONBOARDING_API_REDUCER_KEY = 'onboardingApi'
export const onboardingApi = createApi({
  reducerPath: ONBOARDING_API_REDUCER_KEY,
  baseQuery: baseQueryWithAuth({ baseUrl: applicationUrl }),
  endpoints: (builder) => ({
    onboardUser: builder.mutation<string, IOnboardingData>({
      query: (onboardingData: IOnboardingData) => ({
        url: '/api/onboarding',
        method: 'POST',
        body: { users: [onboardingData] },
        responseHandler: 'text',
        credentials: 'include'
      })
    })
  })
})

export const { useOnboardUserMutation } = onboardingApi

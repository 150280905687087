export enum MaritalStatus {
  MARRIED = 'married',
  PARTNERSHIP = 'partnershipRegistered',
  SINGLE = 'single',
  PARTNERS_NOT_MARRIED = 'partnersNotMarried',
}

export enum Gender {
  MALE = 'male',
  FEMALE = 'female'
}

export enum UserFields {
  PASSWORD = 'password',
  PASSWORD_REPEAT = 'passwordRepeat',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  AGE = 'age',
  EMAIL = 'email',
  PHONE = 'phone',
  PARTNER_PRESENT = 'partner_present',
  RESIDENCE = 'livingTogether',
  MARITAL = 'maritalStatus',
  ASSETS = 'assets',
  CHILDREN = 'hasChildren',
  MARRIAGE = 'marriagePlanned',
  REAL_ESTATES = 'realEstate',
  TERMS = 'acceptTermsAndConditions',
  MARKETING = 'marketing',
  FULL_NAME = 'fullname',
  CITY = 'city',
  POSTAL = 'postal',
  ADDRESS = 'address',
  GENDER = 'gender',
  MARITAL_STATUS = 'maritalStatus',
  TECHNICAL_PROFILE = 'technical_profile'
}

export enum UserTechnicalProfile {
  DIY = 'diy',
  REVIEW = 'review',
  ADVICE = 'advice'
}

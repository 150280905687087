import { FC, PropsWithChildren } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'

import { IconButtonProps } from './IconButton.types'

const IconButton: FC<PropsWithChildren<IconButtonProps>> = ({
  iconClassNames, title, icon, children, ...props
}) => (
  <button
    {...props}
    type="button"
    title={title}
  >
    <FontAwesomeIcon className={classnames(iconClassNames)} icon={icon} />
    {children != null ? children : null}
  </button>
)

export default IconButton

import { useEffect, useState } from 'react'

import { useGetUserDetailsQuery } from '@store/services/user'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import Alert from '@frontend-components/components/Alert'

import { applicationUrl } from '@constants'

const SharedEmailCheck = () => {
  const { t } = useTranslation()
  const [searchParam] = useSearchParams()
  const { data: userDetailsData } = useGetUserDetailsQuery()

  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    if (userDetailsData?.authenticated && searchParam.has('newEmail')) {
      const newEmail = searchParam.get('newEmail')
      const changeEmailToken = searchParam.get('changeEmailToken')
      const updateMail = async () => {
        try {
          const response = await fetch(`${applicationUrl}/user/change-email/${changeEmailToken}?new_email=${newEmail}`)
          if (!response.ok) throw new Error('Could not change email')
          setIsSuccess(true)
        } catch {
          setIsError(true)
        } finally {
          searchParam.delete('newEmail')
          searchParam.delete('changeEmailToken')
        }
      }
      updateMail()
    }
  }, [userDetailsData])
  return (
    <>
      {isSuccess && (
        <Alert type="success" onClose={() => setIsSuccess(false)}>
          {t('sharedEmail.success')}
        </Alert>
      )}
      {isError && <Alert onClose={() => setIsError(false)}>{t('global.errors.genericError')}</Alert>}
    </>
  )
}

export default SharedEmailCheck

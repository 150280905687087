export enum MaritalTypes {
  MARITAL_SINGLE = 'single',
  MARITAL_RELATIONSHIP = 'partnersNotMarried',
  MARITAL_MARRIED = 'married'
}

export enum AssetTypes {
  PROPERTY = 'property',
  VALUE = 'value',
  VEHICLE = 'vehicle',
  SAVING = 'saving'
}

export enum RealEstates {
  COOPERATIVE = 'cooperative',
  OWNER_OCCUPIED = 'ownerOccupied',
  ALLOTMENT = 'allotment',
  COTTAGE = 'cottage'
}

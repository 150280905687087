import { type FC } from 'react'

import { IconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { useRemoveLineItemFromCartMutation } from '@store/services/ecommerce'

type RemoveLineItemButtonProps = {
  lineItemId: string
  cartId: string
  userId: number
}

const RemoveLineItemButton: FC<RemoveLineItemButtonProps> = ({
  lineItemId,
  cartId,
  userId
}) => {
  const [removeLineItem, { isLoading }] = useRemoveLineItemFromCartMutation()

  const handleClick = async () => {
    await removeLineItem({ cartId, lineItemId, userId })
  }

  return (
    <IconButton disabled={isLoading} onClick={handleClick}>
      <DeleteIcon />
    </IconButton>
  )
}

export default RemoveLineItemButton

import { FC, PropsWithChildren } from 'react'
import { createPortal } from 'react-dom'
import classnames from 'classnames'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faExclamationCircle, faTimes } from '@fortawesome/pro-regular-svg-icons'

import { AlertProps, AlertType } from './Alert.types'
import styles from './Alert.module.scss'

const Alert: FC<PropsWithChildren<AlertProps>> = ({
  type = 'error',
  onClose,
  className,
  children,
  ...props
}) => createPortal(
  (
    <div
      {...props}
      className={classnames(styles.wrapper, className, styles[type])}
    >
      <FontAwesomeIcon className={styles.leftIcon} icon={resolveAlertIcon(type)} />
      <div className={styles.content}>{children}</div>
      <button
        type="button"
        onClick={onClose}
        className={styles.closeButton}
        data-cy="alert-close-button"
      >
        <FontAwesomeIcon icon={faTimes} />
      </button>
    </div>
  ), document.body
)

function resolveAlertIcon(type: AlertType) {
  switch (type) {
    case 'warning':
    case 'success':
      return faCheckCircle
    case 'error':
    default:
      return faExclamationCircle
  }
}

export default Alert

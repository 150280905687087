import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  MaritalTypes,
  AssetTypes,
  RealEstates
} from '../../routes/Onboarding/constants'
import { ProductType } from '@frontend-components/types'
import { UserFields, UserTechnicalProfile } from '@frontend-components/constants'

export interface IOnboardingData {
  [UserFields.PARTNER_PRESENT]?: boolean
  [UserFields.MARITAL]?: MaritalTypes
  [UserFields.CHILDREN]?: boolean
  [UserFields.RESIDENCE]?: boolean
  [UserFields.MARRIAGE]?: boolean
  [UserFields.ASSETS]?: AssetTypes[]
  [UserFields.REAL_ESTATES]?: RealEstates[]
  [UserFields.TERMS]?: boolean
  [UserFields.MARKETING]?: boolean
  [UserFields.TECHNICAL_PROFILE]?: UserTechnicalProfile
}

export interface IRecommendedProduct {
  articlePath: string
  categoryId: number
  description: string
  descriptionShort: string
  machineName: ProductType | string
  priority: number
  title: string
}

interface OnboardingState {
  data?: IOnboardingData
}

const initialState: OnboardingState = {
  data: {
    [UserFields.PARTNER_PRESENT]: false,
    [UserFields.MARITAL]: MaritalTypes.MARITAL_SINGLE,
    [UserFields.CHILDREN]: false,
    [UserFields.RESIDENCE]: false,
    [UserFields.MARRIAGE]: false,
    [UserFields.ASSETS]: [],
    [UserFields.REAL_ESTATES]: []
  }
}

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    setOnboardingData(state, action: PayloadAction<Partial<IOnboardingData>>) {
      state.data = { ...state.data, ...action.payload }
    },
    removeOnboardingData(state, action: PayloadAction<Partial<keyof IOnboardingData>>) {
      const data = Object.fromEntries(Object.entries(state?.data ?? {}).filter(([value]) => value !== action.payload))
      state.data = data
    }
  }
})

export const { setOnboardingData, removeOnboardingData } = onboardingSlice.actions
export default onboardingSlice.reducer

import { FC, memo, PropsWithChildren } from 'react'
import classnames from 'classnames'

import { useContext } from '../context'

import { OptionProps } from '../Select.types'

import styles from '../Select.module.scss'

const StyledOption: FC<PropsWithChildren<OptionProps>> = ({
  value,
  index,
  className,
  extraClassName,
  children,
  ...props
}) => {
  const {
    selectedValue,
    highlightedItemIndex,
    setSelectedValue,
    setHighlightedItemIndex,
    isMouseHighlightDisabled,
    setMouseHighlightDisabled
  } = useContext()
  const currentIndex = index ?? 0
  const { 'data-cy': dataCy, ...rest } = props
  const isMultiSelect = Array.isArray(selectedValue)
  const isOptionSelected = isMultiSelect ? selectedValue.includes(value) : selectedValue === value

  const handleMouseDown = () => {
    if (isMultiSelect) {
      if (!selectedValue.includes(value)) {
        setSelectedValue([...selectedValue, value])
      } else {
        setSelectedValue(selectedValue.filter((item) => item !== value))
      }
    } else if (value !== selectedValue) {
      setSelectedValue(value)
    }
  }

  const handleMouseEnter = () => {
    if (highlightedItemIndex === currentIndex || isMouseHighlightDisabled) {
      return
    }

    setHighlightedItemIndex(currentIndex)
  }

  const handleMouseMove = () => {
    if (isMouseHighlightDisabled) {
      setMouseHighlightDisabled(false)
    }
  }

  return (
    <div
      role="presentation"
      {...rest}
      onMouseDown={handleMouseDown}
      onMouseEnter={handleMouseEnter}
      onMouseMove={handleMouseMove}
      className={classnames(styles.option, className, extraClassName, {
        [styles.selected]: isOptionSelected,
        [styles.highlighted]: currentIndex === highlightedItemIndex,
        [styles.enableUserSelect]: isOptionSelected && isMultiSelect
      })}
      data-cy={dataCy != null ? dataCy : `select-option-${value}`}
    >
      {children}
    </div>
  )
}

export default memo(StyledOption)

import { type FC } from 'react'

import { themeColors } from '@testavivadk/css-tools/css/themeColors'

import {
  experimental_extendTheme as materialExtendTheme,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  THEME_ID as MATERIAL_THEME_ID
} from '@mui/material/styles'

import { CssVarsProvider as JoyCssVarsProvider } from '@mui/joy/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { LicenseInfo } from '@mui/x-license'

LicenseInfo.setLicenseKey(
  'ccaa601427df38925dc41b180dc9a3f1Tz04Nzc0NSxFPTE3NDM3Njk1NjAwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
)

interface MuiThemeProviderProps {
  children: React.ReactNode
}

const MuiThemeProvider: FC<MuiThemeProviderProps> = ({ children }) => {
  const theme = materialExtendTheme({
    colorSchemes: {
      light: {
        palette: {
          primary: {
            main: themeColors.darkerTeal
          },
          secondary: {
            main: themeColors.warmPink
          }
        }
      }
    },
    components: {
      MuiAutocomplete: {
        defaultProps: {
          slotProps: { paper: { sx: { minWidth: 'min-content' } } }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none'
          }
        }
      }
    }
  })

  return (
    <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: theme }}>
      <JoyCssVarsProvider>
        <CssBaseline enableColorScheme />
        {children}
      </JoyCssVarsProvider>
    </MaterialCssVarsProvider>
  )
}

export default MuiThemeProvider

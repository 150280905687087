/* eslint-disable function-paren-newline */
import {
  FC,
  useState,
  useEffect,
  useRef,
  PropsWithChildren
} from 'react'
import { createPortal } from 'react-dom'

import cx from 'classnames'
import { useContext } from '../context'
import { useWindowSize } from '../../../hooks/useWindowSize'
import { useThrottle } from '../../../hooks/useThrottle'

import Spinner from '../../Spinner'

import { SelectConstants } from '../Select.constants'
import { DropdownProps } from '../Select.types'
import styles from '../Select.module.scss'

const Dropdown: FC<PropsWithChildren<DropdownProps>> = ({
  inputWrapperRef,
  emptySearchMessage = SelectConstants.emptySearchMessage,
  maxHeight = SelectConstants.maxHeight,
  name,
  dropdownWidth,
  isLoading,
  children
}) => {
  const [dropdownStyles, setDropdownStyles] = useState({
    opacity: 0,
    width: 0,
    maxHeight,
    top: 0,
    left: 0
  })
  const {
    selectedValue,
    highlightedItemIndex,
    isMouseHighlightDisabled
  } = useContext()

  const { height: windowHeight, width: windowWidth } = useWindowSize()
  const dropdownRef = useRef<HTMLDivElement>(null)

  const throttledWindowHeight = useThrottle(windowHeight, SelectConstants.resizeTimeoutMs)
  const throttledWindowWidth = useThrottle(windowWidth, SelectConstants.resizeTimeoutMs)

  useEffect(() => {
    if (throttledWindowHeight === 0 || throttledWindowWidth === 0) return
    calculateDropdownPosition()
  }, [throttledWindowHeight, throttledWindowWidth, children])

  useEffect(() => {
    if (dropdownStyles.opacity === 0) return
    scrollToSelectedOption()
  }, [selectedValue, dropdownStyles])

  useEffect(() => {
    if (highlightedItemIndex > -1 && isMouseHighlightDisabled) {
      scrollToHighlightedItem()
    }
  }, [highlightedItemIndex, isMouseHighlightDisabled])

  let dropdownContent: any = emptySearchMessage

  if (isLoading) {
    dropdownContent = <div className={styles.spinnerWrapper}><Spinner position="center" size="small" /></div>
  } else if (children.length > 0) {
    dropdownContent = children
  }

  return (
    createPortal(
      <div
        data-cy={`dropdown-${name}`}
        ref={dropdownRef}
        className={cx(styles.dropdown, { [styles.empty]: children.length === 0 })}
        style={dropdownStyles}
      >
        {dropdownContent}
      </div>, document.body)
  )

  function calculateDropdownPosition() {
    if (inputWrapperRef.current == null) return

    const inputWrapperRect = inputWrapperRef.current.getBoundingClientRect()
    const width = dropdownWidth ?? inputWrapperRect.width
    // eslint-disable-next-line no-nested-ternary
    const left = dropdownWidth == null ? inputWrapperRect.left : dropdownWidth > inputWrapperRect.width ? inputWrapperRect.left - ((dropdownWidth - inputWrapperRect.width) / 2) : inputWrapperRect.left
    const windowScrollTop = window.scrollY
    const dropdownOffsetTop = inputWrapperRect.top + inputWrapperRect.height + SelectConstants.dropdownGap + windowScrollTop

    let height = maxHeight
    let top = dropdownOffsetTop

    if (dropdownOffsetTop - windowScrollTop + maxHeight > windowHeight) {
      if (inputWrapperRect.top >= maxHeight) {
        const dropdownRect = dropdownRef.current?.getBoundingClientRect()
        const dropdownHeight = dropdownRect?.height ?? maxHeight
        top = inputWrapperRect.top - dropdownHeight + windowScrollTop - SelectConstants.dropdownGap
      } else {
        height = windowHeight - dropdownOffsetTop - SelectConstants.dropdownGap
        if (height < SelectConstants.minHeight) {
          height = SelectConstants.minHeight
        }
      }
    }

    setDropdownStyles({
      width,
      top,
      left,
      maxHeight: height,
      opacity: 1
    })
  }

  function scrollToSelectedOption() {
    const selectedOption = children.find((option: any) => option.props.value === selectedValue)
    if (selectedOption == null) return

    const optionNode = document.getElementsByClassName(selectedOption.props.className)[0]
    if (optionNode == null) return

    const optionRect = optionNode.getBoundingClientRect()
    let scrollTopPosition = 0

    if (optionRect.height > dropdownStyles.maxHeight) {
      scrollTopPosition = optionRect.top - dropdownStyles.top
    } else {
      scrollTopPosition = optionRect.top - dropdownStyles.top - (dropdownStyles.maxHeight / 2) + (optionRect.height / 2)
    }

    dropdownRef.current?.scroll(0, scrollTopPosition)
  }

  function scrollToHighlightedItem() {
    const highlightedOption = dropdownRef.current?.childNodes[highlightedItemIndex] as HTMLDivElement
    if (highlightedOption == null) return

    const { bordersHeight } = SelectConstants
    const numberOfScrolls = Math.ceil(highlightedOption.offsetTop / dropdownStyles.maxHeight)

    if (highlightedOption.offsetTop + (numberOfScrolls * bordersHeight) + highlightedOption.getBoundingClientRect().height > dropdownStyles.maxHeight * numberOfScrolls) {
      dropdownRef.current?.scroll(0, highlightedOption.offsetTop)
    } else if ((dropdownRef.current?.scrollTop ?? 0 + (numberOfScrolls * bordersHeight)) >= highlightedOption.offsetTop + (numberOfScrolls * bordersHeight)) {
      dropdownRef.current?.scroll(0, highlightedOption.offsetTop)
    }
  }
}

export default Dropdown

import { useTranslation } from 'react-i18next'

import { DialogTitle, DialogContent } from '@mui/material'
import MuiLoadingContainer from '@components/MuiLoadingContainer'

const UnlockLoadingContent = () => {
  const { t } = useTranslation()

  return (
    <>
      <DialogTitle>{t('unlockDocumentAfterPaymentModal.unlockLoadingContent.title')}</DialogTitle>
      <DialogContent>
        <MuiLoadingContainer />
      </DialogContent>
    </>
  )
}

export default UnlockLoadingContent

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface BasketState {
  isPurchaseSidebarOpen: boolean
}

const initialState: BasketState = { isPurchaseSidebarOpen: false }

const pricesSlice = createSlice({
  name: 'basket',
  initialState,
  reducers: {
    setPurchaseSidebarOpen(state, action: PayloadAction<BasketState['isPurchaseSidebarOpen']>) {
      state.isPurchaseSidebarOpen = action.payload
    }
  }
})

export const { setPurchaseSidebarOpen } = pricesSlice.actions
export default pricesSlice.reducer

import { FC, useState, useEffect } from 'react'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'

import { useResponsiveness } from '../../hooks/useResponsiveness'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedinIn, faInstagram, faFacebookF } from '@fortawesome/free-brands-svg-icons'
import { faShieldCheck, faChevronDown } from '@fortawesome/pro-solid-svg-icons'

import styles from './Footer.module.scss'

const url = 'https://testaviva.dk'
const currentYear = new Date().getFullYear()
const date = new Date(new Date().toLocaleString('en-US', { timeZone: 'Europe/Copenhagen' }))

const isDaytimeSupportActive = (() => {
  if ([0, 6].includes(date.getDay())) {
    return false
  }
  if (date.getHours() >= 9 && date.getHours() < 17) {
    return true
  }

  return false
})()
const isFridaySupportActive = (() => {
  if (date.getDay() !== 5) {
    return false
  }
  if (date.getHours() >= 9 && date.getHours() < 16) {
    return true
  }

  return false
})()

const Footer: FC = () => {
  const { t } = useTranslation()
  const [openedTabs, setOpenedTabs] = useState<string[]>([])
  const responsiveness = useResponsiveness()

  useEffect(() => {
    if (openedTabs.length > 0 && responsiveness.above.sm) {
      setOpenedTabs([])
    }
  }, [openedTabs, responsiveness])

  const isConditionsVisible = responsiveness.above.sm || openedTabs.includes('conditions')
  const isContactsVisible = responsiveness.above.sm || openedTabs.includes('contacts')

  return (
    <div className={styles.wrapper}>
      <div className="container">
        <div className={styles.content}>
          <div className={classnames(styles.contentColumn, styles.main)}>
            <a className={classnames(styles.regular, styles.white)} href={`${url}/om-testaviva`}>{t('footer.about')}</a>
            <a className={classnames(styles.regular, styles.white)} href={`${url}/viden`}>{t('footer.articles')}</a>
            <a className={classnames(styles.regular, styles.white)} href={`${url}/karriere`}>{t('footer.career')}</a>
            <a className={classnames(styles.regular, styles.white)} href={`${url}/produkter/testamente`}>{t('footer.products')}</a>
            <a className={classnames(styles.regular, styles.white)} href={`${url}/faq`}>{t('footer.faq')}</a>
          </div>
          <div className={classnames(styles.contentColumn, styles.conditions)}>
            <div
              role="presentation"
              className={styles.titleWrapper}
              onClick={() => handleClickTab('conditions')}
            >
              <p className={classnames(styles.regular, styles.white)}>{t('footer.conditions.title')}</p>
              {responsiveness.below.sm ? <FontAwesomeIcon className={classnames(styles.arrowIcon, { [styles.up]: isConditionsVisible })} icon={faChevronDown} /> : null}
            </div>
            {isConditionsVisible ? (
              <div className={styles.conditionsLinks}>
                <a href={`${url}/vilkaar`}>{t('footer.conditions.terms')}</a>
                <a href={`${url}/privatlivspolitik`}>{t('footer.conditions.privacy')}</a>
                <a href={`${url}/persondatapolitik-til-ansogere-i-forbindelse-med-rekrutteringsprocessen`}>{t('footer.conditions.privacy2')}</a>
                <a href={`${url}/cookie-politik`}>{t('footer.conditions.cookie')}</a>
              </div>
            ) : null }
          </div>
          <div className={classnames(styles.contentColumn, styles.social)}>
            <p className={styles.white}>{t('footer.follow')}</p>
            <div className={styles.socialItems}>
              <a target="_blank" rel="noreferrer" href={t('global.contactInfo.facebookUrl') as string}>
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
              <a target="_blank" rel="noreferrer" href={t('global.contactInfo.linkedinUrl') as string}>
                <FontAwesomeIcon icon={faLinkedinIn} />
              </a>
              <a target="_blank" rel="noreferrer" href={t('global.contactInfo.instagramUrl') as string}>
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            </div>
          </div>
        </div>
        <div className={styles.contacts}>
          <div
            role="presentation"
            className={styles.titleWrapper}
            onClick={() => handleClickTab('contacts')}
          >
            <p className={classnames(styles.regular, styles.white, styles.contactsTitle)}>{t('footer.contact.title')}</p>
            {responsiveness.below.sm ? <FontAwesomeIcon className={classnames(styles.arrowIcon, { [styles.up]: isContactsVisible })} icon={faChevronDown} /> : null}
          </div>
          {isContactsVisible ? (
            <div className={styles.content}>
              <div className={styles.contentColumn}>
                <div className={styles.columnItem}>
                  <div className={styles.support}>
                    <span className={classnames(styles.indicator, { [styles.active]: isDaytimeSupportActive })} />
                    <p className={styles.white}>{t('footer.contact.daytimes')}</p>
                  </div>
                  <div className={styles.support}>
                    <span className={classnames(styles.indicator, { [styles.active]: isFridaySupportActive })} />
                    <p className={styles.white}>{t('footer.contact.friday')}</p>
                  </div>
                </div>
                <p className={classnames(styles.white, styles.holiday)}>{t('footer.contact.holiday')}</p>
              </div>
              <div className={styles.contentColumn}>
                <div className={styles.columnItem}>
                  <p>{t('footer.contact.doYouNeedHelp')}</p>
                  <a className={styles.yellow} href={`${url}/testaviva-support`}>{t('global.contactInfo.goToSupport')}</a>
                </div>
                <div className={styles.columnItem}>
                  <p>{t('footer.contact.emailLabel')}</p>
                  <a className={styles.white} href={`mailto:${t('global.contactInfo.email')}`}>{t('global.contactInfo.email')}</a>
                  <p className={classnames(styles.white, styles.spaceRight)}>
                    {t('global.contactInfo.boligEmailText')}
                    {' '}
                    <a className={styles.white} href={`mailto:${t('global.contactInfo.boligEmail')}`}>{t('global.contactInfo.boligEmail')}</a>
                  </p>
                </div>
              </div>
              <div className={styles.contentColumn}>
                <div className={styles.columnItem}>
                  <p>{t('footer.contact.addressLabel')}</p>
                  <p className={styles.white} dangerouslySetInnerHTML={{ __html: t('global.contactInfo.address') ?? '' }} />
                </div>
                <div className={classnames(styles.columnItem, styles.singleLine)}>
                  <p>{t('footer.contact.cvrLabel')}</p>
                  <p className={styles.white}>{t('global.contactInfo.cvr')}</p>
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <div className={classnames(styles.content, styles.bottom)}>
          <div className={styles.footerItem}>
            <FontAwesomeIcon className={styles.icon} icon={faShieldCheck} />
            <p className={styles.footerText}>{t('footer.gdpr')}</p>
          </div>
          <p className={styles.footerText}>
            {t('footer.copyright')}
            {' '}
            {currentYear}
          </p>
        </div>
      </div>
    </div>
  )

  function handleClickTab(name: string) {
    if (responsiveness.above.sm) {
      return
    }

    if (openedTabs.includes(name)) {
      setOpenedTabs((prevState) => prevState.filter((tab) => tab !== name))
    } else {
      setOpenedTabs((prevState) => [...prevState, name])
    }
  }
}

export default Footer

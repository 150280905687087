export function getStorageItem(key: string): unknown {
  const data = localStorage.getItem(key)
  return data != null ? JSON.parse(data) : null
}

export function setStorageItem<T>(key: string, data: T): void {
  localStorage.setItem(key, JSON.stringify(data))
}

export function removeStorageItem(key: string): void {
  localStorage.removeItem(key)
}

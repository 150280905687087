import { FC, MouseEvent, useRef } from 'react'
import classnames from 'classnames'

import { extractTextFromReact } from '../../utils/extractTextFromReact'

import Spinner from '../Spinner'

import { ButtonProps, ButtonKinds, ButtonSizes } from './Button.types'
import styles from './Button.module.scss'

const defaultKind = ButtonKinds.primary
const defaultSize = ButtonSizes.medium

const Button: FC<ButtonProps> = ({
  disabled = false,
  className = '',
  kind = defaultKind,
  type = 'button',
  size = defaultSize,
  icon = null,
  onClick = null,
  isLoading = false,
  children,
  color = 'teal',
  href = null,
  ...props
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null)
  const areaLabelText = props['aria-label'] ?? extractTextFromReact(children)

  return href == null ? (
    <button
      {...props}
      ref={buttonRef}
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={classnames(styles.button, styles[kind], styles[size], styles[color], className, { [styles.disabled]: disabled, [styles.loading]: isLoading })}
      disabled={disabled}
      onClick={handleClick}
      aria-label={areaLabelText}
    >
      {isLoading ? <Spinner size="small" className={styles.buttonSpinner} /> : null}
      <span style={{ opacity: isLoading ? 0 : 1 }}>
        {icon != null ? <span className={styles.icon}>{icon}</span> : null}
        {' '}
        {children}
      </span>
    </button>
  ) : (
    <a
      {...(props?.['data-cy'] != null ? { 'data-cy': props['data-cy'] } : null)}
      href={href}
      type={type}
      className={classnames(styles.link, styles[kind], styles[size], styles[color], className, { [styles.disabled]: disabled, [styles.loading]: isLoading })}
      aria-label={areaLabelText}
    >
      {isLoading ? <Spinner size="small" className={styles.buttonSpinner} /> : null}
      <span style={{ opacity: isLoading ? 0 : 1 }}>
        {icon != null ? <span className={styles.icon}>{icon}</span> : null}
        {' '}
        {children}
      </span>
    </a>
  )

  function handleClick(event: MouseEvent<HTMLButtonElement>) {
    if (disabled || isLoading) {
      return
    }
    buttonRef?.current?.blur()
    if (typeof onClick === 'function') {
      onClick(event)
    }
  }
}

export default Button

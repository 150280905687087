import {
  FC, useState, useEffect, useCallback, cloneElement, forwardRef, ForwardedRef, PropsWithChildren
} from 'react'
import { createPortal } from 'react-dom'
import classnames from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'

import { useWindowSize } from '../../hooks/useWindowSize'
import { useDisablePageScrolling } from '../../hooks/useDisablePageScrolling'

import { DrawerProps } from './Drawer.types'
import styles from './Drawer.module.scss'
import { defaultOpacity, defaultWidth, defaultDurationS } from './Drawer.constants'

// @ts-ignore
const Drawer: FC<PropsWithChildren<DrawerProps>> = forwardRef(({
  open,
  onClose = null,
  children,
  config,
  header,
  classNames = {
    overlay: undefined,
    scrollArea: undefined
  },
  drawerExtraProps = {
    overlay: {},
    wrapper: {},
    scrollArea: {}
  },
  disableDocumentScrolling = true,
  ...props
}, ref: ForwardedRef<HTMLDivElement>) => {
  const [isOpen, setOpen] = useState(open)
  const [isUserSelectDisabled, setUserSelectDisabled] = useState(false)
  const [isDraggable, setDraggable] = useState(false)
  const { width: windowWidth } = useWindowSize()

  const drawerWidth = (() => {
    const width = config?.width ?? defaultWidth
    if (windowWidth < width || windowWidth <= 480) {
      return windowWidth
    }
    return width
  })()
  const overlayOpacity = config?.opacity ?? defaultOpacity
  const animationS = config?.animationDuration ?? defaultDurationS

  const toggleDrawer = useCallback((openValue: boolean, runCloseCallback = false) => {
    if (openValue) {
      setOpen(true)
    } else {
      setOpen(false)
      if (runCloseCallback && typeof onClose === 'function') {
        onClose()
      }
    }
  }, [onClose])

  useDisablePageScrolling(isOpen, disableDocumentScrolling)

  useEffect(() => {
    toggleDrawer(open)
  }, [open])

  useEffect(() => {
    if (typeof onClose !== 'function') {
      toggleDrawer(isOpen)
    }
  }, [isOpen, onClose])

  const headerElement = header != null ? cloneElement(header, { ...header.props, className: styles.header }, header.props.children) : null

  return createPortal(
    (
      <>
        <AnimatePresence mode="wait">
          {isOpen ? (
            <motion.div
              {...drawerExtraProps.overlay}
              className={classnames(styles.overlay, classNames.overlay)}
              initial={{ opacity: 0 }}
              animate={{ opacity: overlayOpacity }}
              exit={{ opacity: 0 }}
              onClick={() => toggleDrawer(false, true)}
              data-cy="drawer-overlay"
            />
          ) : null}
        </AnimatePresence>
        <AnimatePresence mode="wait">
          {isOpen ? (
            <motion.div
              {...drawerExtraProps.wrapper}
              {...props}
              className={classnames(styles.drawer, { [styles.disableSelect]: isUserSelectDisabled })}
              initial={{ x: drawerWidth, right: 0 }}
              animate={{ x: 0 }}
              exit={{ x: drawerWidth, right: -drawerWidth }}
              transition={{ duration: animationS, ease: 'easeInOut' }}
              onAnimationComplete={() => setDraggable(true)}
              style={{ width: drawerWidth }}
              drag={isDraggable ? 'x' : false}
              dragConstraints={{ left: 0, right: drawerWidth }}
              dragElastic={0}
              dragSnapToOrigin
              dragTransition={{ bounceStiffness: 600, bounceDamping: 150 }}
              onDrag={() => {
                if (!isUserSelectDisabled) {
                  setUserSelectDisabled(true)
                }
              }}
              onDragEnd={(_, info) => {
                setUserSelectDisabled(false)
                if (info.offset.x > drawerWidth / 2) {
                  setOpen(false)
                  if (typeof onClose === 'function') {
                    onClose()
                  }
                }
              }}
            >
              {headerElement}
              <div
                {...drawerExtraProps.scrollArea}
                className={classnames(styles.drawerScroll, classNames.scrollArea)}
                style={{ touchAction: 'pan-y' }}
                ref={ref}
              >
                {children}
              </div>
            </motion.div>
          ) : null}
        </AnimatePresence>
      </>
    ), document.body
  )
})

Drawer.displayName = 'Drawer'

export default Drawer

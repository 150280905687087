import React, { Component } from 'react'
import Message from '../Message'

interface ErrorBoundaryProps {
  children: React.ReactNode
  fallbackComponent?: any
  hideErrorMessage?: boolean
}

interface ErrorBoundaryState {
  hasError: boolean
  error?: Error
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)

    this.state = {
      hasError: false,
      error: undefined
    }
  }

  static getDerivedStateFromError(): any {
    return { hasError: true }
  }

  public componentDidCatch(error: Error): void {
    this.setState({ error })
  }

  public render(): React.ReactNode {
    const { hasError, error } = this.state
    const { fallbackComponent, hideErrorMessage, children } = this.props

    if (hasError) {
      if (hideErrorMessage) {
        return null
      }
      if (fallbackComponent != null && error != null) {
        const ComponentToRender = fallbackComponent
        return <ComponentToRender error={error} />
      }

      return (
        <div className="container">
          <Message type="error">
            Der skete en uventet fejl. Prøv at indlæse siden igen.
            Hvis du stadig modtager fejlen, kan du kontakte os på support@testaviva.dk,
            så vender vi tilbage hurtigst muligt. Husk at du altid kan tilgå dine dokumenter under menuen på mine dokumenter.
          </Message>
        </div>
      )
    }

    return children
  }
}

export default ErrorBoundary

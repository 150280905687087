import { useState, useEffect } from 'react'
import cookie from 'js-cookie'

const LAZY_LOAD_RETRY_COOKIE_NAME = 'lazy-load-retry'

export function useLazyLoadRetry(error?: Error): boolean[] {
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    if (error == null) {
      return
    }

    const lazyLoadCookie = cookie.get(LAZY_LOAD_RETRY_COOKIE_NAME)

    // eslint-disable-next-line no-useless-escape
    const chunkFailedMessage = /Loading chunk [\a-zA-Z-0-9]+ failed/
    if (error?.message && chunkFailedMessage.test(error.message)) {
      // Make sure user doesn't get inside infinitive loop of page refreshes in case if chunk is missing. Show an error if chunk fails to load again.
      if (lazyLoadCookie == null) {
        const currentDate = new Date()
        const newDate = new Date()

        newDate.setTime(currentDate.getTime() + (30 * 1000))
        cookie.set(LAZY_LOAD_RETRY_COOKIE_NAME, 'true', { expires: newDate })
        window.location.reload()
        return
      }
      setLoading(false)
    }
  }, [error])

  return [isLoading]
}

export enum ButtonKinds {
  primary = 'primary',
  secondary = 'secondary',
  text = 'text',
  pink = 'pink',
}

export enum Colors {
  teal = 'teal',
  pink = 'pink',
  red = 'red',
  grey = 'grey'
}

export enum ButtonSizes {
  small = 'small',
  medium = 'medium',
  fluid = 'fluid',
  smallFluid = 'smallFluid'
}

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  kind?: keyof typeof ButtonKinds
  size?: keyof typeof ButtonSizes
  disabled?: boolean
  isLoading?: boolean
  icon?: any
  href?: string
  'data-cy'?: string
}

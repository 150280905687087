// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import cookieJs, { CookieAttributes } from 'js-cookie'

export class Cookie {
  static get(name: string): string | undefined {
    return cookieJs.get(name)
  }

  static set(name: string, value: string, options?: CookieAttributes): string | undefined {
    return cookieJs.set(name, value, options)
  }

  static remove(name: string, options?: CookieAttributes): void {
    cookieJs.remove(name, options)
  }
}

import { FC, useEffect, memo } from 'react'
import Provider from './context/Provider'

import { DeviceDetection } from '../../utils/deviceDetection'
import { useContext } from './context'

import NativeSelect from './components/NativeSelect'
import StyledSelect from './components/StyledSelect'

import { SelectProps } from './Select.types'

const isTouchDevice = DeviceDetection.isTouchDevice()

const SelectResolver: FC<SelectProps> = ({
  isNative = false,
  onChange,
  value,
  isMultiSelect = false,
  ...props
}) => {
  const selectProps = {
    value, onChange, isMultiSelect, ...props
  }
  const selectElement = (isTouchDevice || isNative) && !isMultiSelect ? <NativeSelect {...selectProps} /> : <StyledSelect {...selectProps} />
  const { selectedValue, setSelectedValue, setNativeSelect } = useContext()

  useEffect(() => {
    setNativeSelect(isNative)
  }, [])

  useEffect(() => {
    if (!value || value.length === 0) return

    setSelectedValue(value)
  }, [value])

  useEffect(() => {
    if (typeof onChange !== 'function') return
    if (Array.isArray(selectedValue) && JSON.stringify(selectedValue) === JSON.stringify(value)) return
    if (!Array.isArray(selectedValue) && selectedValue === value) return
    onChange(selectedValue)
  }, [selectedValue, value])

  return selectElement
}

const Select: FC<SelectProps> = (props) => (
  <Provider>
    <SelectResolver {...props} />
  </Provider>
)

export default memo(Select)

import { useGetCartQuery } from '@store/services/ecommerce'
import { useGetUserDetailsQuery } from '@store/services/user'
import { useTranslation } from 'react-i18next'

import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Skeleton,
  Button
} from '@mui/material'
import RemoveLineItemButton from './components/RemoveLineItemButton'
import QuantitySelect from './components/QuantitySelect'
import VariantSelect from './components/VariantSelect'

import { formatPrice } from '@testavivadk/utilities'

const CartContent = () => {
  const { data: userDetailsData } = useGetUserDetailsQuery()
  const { data: cartData, isLoading } = useGetCartQuery({ userId: Number(userDetailsData?.id) })
  const { t } = useTranslation()

  if (isLoading || userDetailsData == null) {
    return (
      <Box>
        <Card>
          <CardHeader
            sx={{ ml: 2, minWidth: '20rem', height: '3rem' }}
            component={Skeleton}
          />
          <CardContent>
            <Box
              sx={{
                display: 'flex',
                gap: 2
              }}
            >
              <Skeleton width={100} />
              <Skeleton width={100} />
              <Skeleton width={100} />
            </Box>
            <Skeleton width="100%" height="2rem" />
            <Skeleton width={200} />
          </CardContent>
        </Card>
      </Box>
    )
  }

  if (cartData == null || cartData.lines.length === 0) {
    return (
      <Box sx={{ minHeight: '100%', p: 4 }}>
        <Typography textAlign="center" variant="body1">
          {t('cart.noItems')}
        </Typography>
      </Box>
    )
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2
      }}
    >
      {cartData.lines.map((product) => (
        <Card key={product.lineId}>
          <CardHeader
            title={product.title}
            action={(
              <RemoveLineItemButton
                cartId={cartData.cartId}
                lineItemId={product.lineId}
                userId={userDetailsData.id}
              />
            )}
          />
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2
            }}
          >
            <VariantSelect lineItem={product} cartId={cartData.cartId} />
            <QuantitySelect lineItem={product} cartId={cartData.cartId} />
            <Typography variant="body1">
              {`${t('cart.price')} ${formatPrice(product.linePrice)}`}
            </Typography>
          </CardContent>
        </Card>
      ))}
      <Button href={cartData.checkoutUrl} variant="contained" target="_parent">
        {t('cart.checkout')}
      </Button>
    </Box>
  )
}

export default CartContent

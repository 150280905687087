import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { useLazyLoadRetry } from '@frontend-components/hooks/useLazyLoadRetry'

import HeaderWrapper from '@frontend-components/components/HeaderWrapper'
import Spinner from '@frontend-components/components/Spinner'
import Message from '@frontend-components/components/Message'

const LazyLoadRetry: FC<{ error: Error }> = ({ error }) => {
  const { t } = useTranslation()
  const [isLoading] = useLazyLoadRetry(error)

  return (
    <>
      <HeaderWrapper />
      {isLoading ? <Spinner position="screen-center" /> : <Message type="error">{t('global.errors.genericError')}</Message>}
    </>
  )
}

export default LazyLoadRetry

import {
  FC, useEffect, useState
} from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { getAuthToken } from '@utils'

import {
  AppBar, Button, Dialog, Toolbar, Typography, Alert,
  Snackbar,
  IconButton
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'
import { authHeaderName } from '@frontend-components/constants'
import { fileDownloadUrlParam } from '@constants'
import styles from './OpenPdfFileModal.module.scss'

const OpenPdfFileModal: FC = () => {
  const { t } = useTranslation()

  const [isPdfModalOpen, setIsPdfModalOpen] = useState(false)
  const [pdfUrl, setPdfUrl] = useState<string | null>(null)

  const [isError, setError] = useState(false)

  const [searchParams, setSearchParams] = useSearchParams()

  const fetchPdf = async () => {
    const authToken = getAuthToken()
    const downloadUrl = searchParams.get(fileDownloadUrlParam)

    if (downloadUrl == null) return

    const headers: { [key: string] : string } = { 'Content-Type': 'application/json' }
    if (authToken != null && authToken.length > 0) {
      headers[authHeaderName] = authToken
    }

    try {
      const response = await fetch(downloadUrl, { method: 'GET', headers })
      if (response.ok) {
        const blob: Blob = await response.blob()
        const url = URL.createObjectURL(blob)
        setPdfUrl(url)
        setIsPdfModalOpen(true)
      }
    } catch (error) {
      console.error(error)
      setError(true)
      setIsPdfModalOpen(false)
    }
  }

  useEffect(() => {
    fetchPdf()
  }, [])

  const handleClickClose = () => {
    setSearchParams('', { replace: true })
    setIsPdfModalOpen(false)
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={isError}
        onClose={() => setError(false)}
      >
        <Alert
          sx={{ minWidth: '100%' }}
          variant="filled"
          severity="error"
          action={(
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setError(false)
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          )}
        >
          {t('global.errors.genericError')}
        </Alert>
      </Snackbar>
      <Dialog fullScreen open={isPdfModalOpen} onClose={handleClickClose}>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {t('openPdfFileModal.pdf')}
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClickClose}>
              {t('openPdfFileModal.close')}
            </Button>
          </Toolbar>
        </AppBar>
        <iframe className={styles.iframe} title={t('openPdfFileModal.iframeTitle')} src={pdfUrl ?? ''} />
      </Dialog>
    </>
  )
}

export default OpenPdfFileModal
